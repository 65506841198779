import React from "react";

import { graphql } from "gatsby";

import { Seo, TagsContainer } from "../components";
import Layout from "../components/Layout";

const TagsPage = ({ data }) => {
  const tags = data.allTags.nodes;
  const allTags = tags.map(tag => tag.data.tags);
  const mergedTags = [].concat.apply([], allTags);
  const uniqueTags = [...new Set(mergedTags)].sort();
  return (
    <Layout>
      <Seo title="Tags" />
      <TagsContainer title="Tags" tags={uniqueTags} />
    </Layout>
  );
};

export const query = graphql`
  {
    allTags: allAirtable(
      filter: {
        table: { eq: "catalogue" }
        data: { status: { eq: "Available" } }
      }
    ) {
      nodes {
        data {
          tags
        }
      }
    }
  }
`;

export default TagsPage;
